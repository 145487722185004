@import url('https://fonts.googleapis.com/css2?family=Saira:ital,wght@0,100..900;1,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body::-webkit-scrollbar {
    display: none;
}

body::-webkit-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  body {
    font-family: 'Saira', sans-serif;
  }

  .home-main-scale .slick-slide {
    /* margin-left: 20px; */
    padding-right: 20px;
  }

  .home-partner .slick-slide {
    margin-left: 20px;
  }